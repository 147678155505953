import React from 'react';
import { graphql, PageProps } from 'gatsby';
import tw from 'twin.macro';
import css from '@emotion/css';
import BaseLayout from '../layouts/base';
import Img, { FluidObject } from 'gatsby-image';
import { GetHomeQuery } from '../graphql-types';
import Partner from '../components/partner';
import Testimonials from '../components/testimonials';
import { Helmet } from 'react-helmet';
import {
  BtnPrimary,
  BtnLinkPrimary,
  BtnLinkScrollPrimary,
  HyperlinkPrimary,
} from '../components/scaffolds';

import { INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SliderJumbotron from '../components/slider-jumbotron';

const commonOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const url = node.data.uri;
      return <HyperlinkPrimary href={url}>{children}</HyperlinkPrimary>;
    },
  },
};

const ProductContainer = tw.div`
  flex-wrap lg:flex lg:justify-center mb-14 text-left
`;
const ProductBox: React.FC<{ style?: React.CSSProperties; className?: string }> = ({
  children,
  style,
  className,
}) => (
  <div tw="w-full lg:w-1/4 pb-4 lg:p-2" style={style} className={className}>
    <div tw="bg-white dark:bg-black-300 w-full h-full p-8 rounded-2xl flex flex-col justify-between">
      {children}
    </div>
  </div>
);

const icons = [];

class Home extends React.Component<PageProps<GetHomeQuery>> {
  getSufixPrice(price: number) {
    if (!price) {
      return '';
    }
    let num = price;
    let sufix = num / 1000 >= 1000 ? 'juta' : 'ribu';
    return sufix;
  }

  formatPrice(price: number) {
    if (!price) {
      return '';
    }
    let num = price;
    num = num / 1000 >= 1000 ? num / 1000000 : num / 1000;

    let formattedPrice = num.toString();
    return formattedPrice.replace('.', ',');
  }

  getIconUrl(title) {
    let name = title;
    if (title === 'PT') {
      name = 'icon-pt';
    }
    if (title === 'Building') {
      name = 'icon-umkm';
    }
    if (title === 'Shop') {
      name = 'icon-pariwisata';
    }
    if (title === 'Home') {
      name = 'icon-group';
    }
    return icons.filter(i => i.title === name).map(i => i.file.url)[0];
  }

  render() {
    const data = this.props.data;
    const pageData = data?.allContentfulHome.nodes[0];
    const image = pageData?.jumbotron?.fluid;
    const banners = pageData?.promoBanners;
    const bannersDetail = pageData?.bannersDetail;
    const jumbotronButtonText = pageData?.jumbotronButtonText;
    let hashtag = '';
    if (typeof window !== "undefined") {
      hashtag = window.location.hash;
    }

    const logo = data?.logo?.childrenImageSharp[0]?.fluid;
    const partners = pageData?.partner?.filter(p => !p.title.includes('DISABLE')) || [];

    icons.push(...data?.icons?.nodes);

    // SEO data
    const siteMeta = data.site?.siteMetadata;
    const canonical = `${siteMeta.url}/`;
    const seo = pageData?.seo;
    let product = [
      ...pageData?.mainService?.map(p => {
        return {
          '@type': 'Product',
          name: p.title,
          description: p.detail,
          offers: {
            '@type': 'Offer',
            url: canonical + p.link,
            priceCurrency: 'IDR',
            price: p.price,
          },
        };
      }),
      ...pageData?.otherService?.map(p => {
        return {
          '@type': 'Product',
          name: p.title,
          description: p.detail,
          offers: {
            '@type': 'Offer',
            url: canonical + p.link,
            priceCurrency: 'IDR',
            price: p.price,
          },
        };
      }),
    ];

    const jsonLd = {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'Organization',
          url: 'https://easybiz.id',
          name: 'Easybiz',
          legalName: 'PT Justika Solusi Indonesia',
          logo:
            'https://newbetheme.easybiz.id/wp-content/uploads/2021/02/Easybiz-Logo-Hukumonline.com_.png',
          address: {
            '@type': 'PostalAddress',
            streetAddress: 'AD Premier Office Park Lantai 9 Jl. TB Simatupang No. 5',
            addressLocality: 'Ragunan, Pasar Minggu, Jakarta Selatan',
            addressRegion: 'DKI Jakarta',
            postalCode: '12550',
            addressCountry: 'Indonesia',
          },
          contactPoint: {
            '@type': 'ContactPoint',
            contactType: 'sales',
            telephone: '+6221-227-089-03',
            email: 'halo@easybiz.id',
          },
          sameAs: [
            'https://www.facebook.com/easybizID',
            'https://www.instagram.com/easybiz.id/?hl=en',
            'https://twitter.com/easybizid',
            'https://www.linkedin.com/company/easybiz',
          ],
        },
        {
          '@type': 'WebSite',
          url: canonical,
          potentialAction: {
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: `${siteMeta.url}/search/?query={search_term_string}`,
            },
            'query-input': 'required name=search_term_string',
          },
        },
        {
          '@type': 'LocalBusiness',
          address: {
            '@type': 'PostalAddress',
            addressLocality: 'Jakarta Selatan',
            addressRegion: 'JK',
            streetAddress: 'AD Premier Office Park Lantai 9 Jl. TB Simatupang No. 5 Ragunan',
            addressCountry: 'ID',
          },
          name: 'Easybiz',
          image: [pageData?.jumbotron?.file?.url],
          telephone: '081617369369',
        },
        {
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              name: 'Home',
              item: 'https://easybiz.id',
            },
          ],
        },
        ...product,
      ],
    };

    return (
      <BaseLayout logo={logo}>
        <Helmet>
          <title>{seo?.title || 'Jasa Pendirian PT, Badan Usaha, dan Perizinan di Jakarta.'}</title>
          <meta
            name="description"
            content={
              seo?.description?.description ||
              'Jasa Pendirian PT, Badan Usaha, dan Perizinan di Jakarta'
            }
          />
          <meta name="keywords" content={seo?.keyword} />
          <meta
            name="robots"
            content={
              seo?.robots ||
              'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
            }
          />
          <link rel="canonical" href={canonical} />
          <meta name="author" content={seo?.author} />
          <meta name="publisher" content={seo?.publisher} />
          <meta property="og:locale" content={seo?.oglocale || 'en_US'} />
          <meta property="og:type" content={seo?.ogtype || 'website'} />
          <meta
            property="og:title"
            content={seo?.title || 'Jasa Pendirian PT, Badan Usaha, dan Perizinan di Jakarta.'}
          />
          <meta
            property="og:description"
            content={
              seo?.description?.description ||
              'Jasa Pendirian PT, Badan Usaha, dan Perizinan di Jakarta'
            }
          />
          <meta property="og:url" content={canonical} />
          <meta property="og:image" content={pageData?.jumbotron?.file?.url} />
          <meta property="og:site_name" content="Easybiz" />
          <meta
            name="twitter:title"
            content={seo?.title || 'Jasa Pendirian PT, Badan Usaha, dan Perizinan di Jakarta.'}
          />
          <meta
            name="twitter:description"
            content={
              seo?.description?.description ||
              'Jasa Pendirian PT, Badan Usaha, dan Perizinan di Jakarta'
            }
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content={canonical} />
          <meta name="twitter:image" content={pageData?.jumbotron?.file?.url} />
          <meta name="twitter:creator" content={seo?.author} />
          <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
        </Helmet>
        {banners ? (
          <SliderJumbotron
            banners={banners}
            homeImage={image}
            pageTitle={pageData?.title}
            pageSubtitle={documentToReactComponents(
              JSON.parse(pageData?.subtitle?.raw),
              commonOptions,
            )}
            bannersDetail={bannersDetail}
            hashtag={hashtag}
            jumbotronButtonText={jumbotronButtonText}
          ></SliderJumbotron>
        ) : (
          <section
            tw="bg-white dark:bg-black-200"
            css={css`
              overflow: hidden;
              height: 659px;
              top: 0px;
              position: relative;
              @media (max-width: 1024px) {
                height: 488px;
                top: 0px;
              }
            `}
          >
            <div
              tw="bg-black-100"
              css={css`
                top: -421px;
                position: relative;
                width: calc(100% + 1560px);
                height: 1080px;
                overflow: hidden;
                border-radius: 100%;
                left: -780px;
                @media (max-width: 1024px) {
                  top: -48px;
                  left: -195px;
                  width: calc(100% + 390px);
                  height: 536px;
                }
              `}
            >
              <div
                tw="flex justify-center"
                css={css`
                  position: absolute;
                  left: 780px;
                  width: calc(100% - 1560px);
                  bottom: 0;
                  top: 421px;
                  @media (max-width: 1024px) {
                    left: 195px;
                    top: 115px;
                    width: calc(100% - 390px);
                  }
                `}
              >
                <Img
                  alt={pageData?.title}
                  fluid={image as FluidObject}
                  css={css`
                    height: 100%;
                    width: 100%;
                  `}
                />
                <div
                  tw="w-full h-full flex items-center"
                  css={css`
                    background-color: rgba(39, 40, 46, 0.64);
                    top: 0;
                    position: absolute;
                  `}
                >
                  <div tw="container">
                    <div tw="lg:w-1/3 mx-8 lg:mx-32">
                      <h1 tw="text-h2 lg:text-h1 text-white">{pageData?.title}</h1>
                      <div tw="text-b1 text-white mb-6">
                        {documentToReactComponents(
                          JSON.parse(pageData?.subtitle?.raw),
                          commonOptions,
                        )}
                      </div>
                      <BtnLinkScrollPrimary className="gtm-all-service" to="/#layanan-dan-harga">
                        {jumbotronButtonText || 'Lihat Layanan Kami'}
                      </BtnLinkScrollPrimary>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        <Partner partners={partners} dynamic={true}></Partner>
        <section tw="bg-black-500 dark:bg-black-100">
          <div tw="container">
            <div tw="grid grid-cols-2 lg:grid-cols-3 lg:gap-4 mx-8 lg:mx-32 py-4">
              {pageData?.achievement?.map((a, i) => {
                return (
                  <div key={i + '-achievement'}>
                    <h4 tw="text-h2 text-black-100 dark:text-white py-4 m-0">{a.title}</h4>
                    <h3 tw="text-b1 font-ibm text-black-100 dark:text-white">{a.detail}</h3>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section tw="bg-black-700 dark:bg-black-200">
          <div tw="container">
            <div tw="mx-8 lg:mx-32 lg:text-center py-12">
              <h2 tw="text-black-100 dark:text-white pb-8">
                Jadikan <b tw="text-blue-lapis dark:text-orange">Easybiz Partner Anda</b> dalam
                Memulai Bisnis
              </h2>
              <div tw="bg-white p-8 rounded-2xl">
                <div tw="grid grid-cols-1 lg:grid-cols-2 lg:gap-4 text-left">
                  <div tw="flex">
                    <object>
                      <embed
                        width="80px"
                        height="90px"
                        tw="mr-4 flex-none"
                        css={css`
                          @media (max-width: 1024px) {
                            height: 45px;
                            width: 40px;
                          }
                        `}
                        src={this.getIconUrl('icon-akurat')}
                      ></embed>
                    </object>
                    <div>
                      <h3 tw="text-h4 lg:text-h3 my-2">Hasil yang Akurat</h3>
                      <p tw="text-b1 text-black-100">
                        Legalitas dokumen dan perizinan sesuai dengan peraturan yang berlaku.
                      </p>
                    </div>
                  </div>
                  <div tw="flex">
                    <object>
                      <embed
                        width="80px"
                        height="90px"
                        tw="mr-4 flex-none"
                        css={css`
                          @media (max-width: 1024px) {
                            height: 45px;
                            width: 40px;
                          }
                        `}
                        src={this.getIconUrl('icon-praktis')}
                      ></embed>
                    </object>
                    <div>
                      <h3 tw="text-h4 lg:text-h3 my-2">Langkah Praktis</h3>
                      <p tw="text-b1 text-black-100">
                        Easybiz menjadi jalan pintas yang tepat untuk Anda yang ingin memulai
                        bisnis.
                      </p>
                    </div>
                  </div>
                  <div tw="flex">
                    <object>
                      <embed
                        width="80px"
                        height="90px"
                        tw="mr-4 flex-none"
                        css={css`
                          @media (max-width: 1024px) {
                            height: 45px;
                            width: 40px;
                          }
                        `}
                        src={this.getIconUrl('icon-personalized')}
                      ></embed>
                    </object>
                    <div>
                      <h3 tw="text-h4 lg:text-h3 my-2">Personalized</h3>
                      <p tw="text-b1 text-black-100">
                        Easybiz akan menyesuaikan paket yang tepat sesuai kebutuhan perusahaan Anda.
                      </p>
                    </div>
                  </div>
                  <div tw="flex">
                    <object>
                      <embed
                        width="80px"
                        height="90px"
                        tw="mr-4 flex-none"
                        css={css`
                          @media (max-width: 1024px) {
                            height: 45px;
                            width: 40px;
                          }
                        `}
                        src={this.getIconUrl('icon-harga')}
                      ></embed>
                    </object>
                    <div>
                      <h3 tw="text-h4 lg:text-h3 my-2">Harga Terjangkau</h3>
                      <p tw="text-b1 text-black-100">
                        Harga yang kami tawarkan sesuai dengan keuntungan yang akan Anda dapatkan.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section tw="bg-blue-marine dark:bg-blue-black relative" id="layanan-dan-harga">
          <div
            css={css`
              background: linear-gradient(
                270deg,
                rgba(45, 64, 92, 0.4) 0%,
                rgba(0, 0, 0, 0.4) 100%
              );
            `}
          >
            <div tw="lg:text-center mx-8 lg:mx-4 lg:text-center py-12 relative z-10">
              <h2 tw="text-white mb-10">
                <b tw="text-yellow-bright">Layanan</b> Unggulan Easybiz
              </h2>
              {/* <p tw="text-b1 text-white mb-4">Pilihan paket pendirian dan perizinan usaha:</p> */}
              <ProductContainer tw="mb-5">
                {pageData?.mainService?.map((main, i) => {
                  return (
                    <ProductBox key={i + 'productmain'} tw="relative mb-6 lg:mb-2">
                      <div tw="text-black-100 dark:text-white">
                        <object>
                          <embed height="120px" src={this.getIconUrl(main.icon)}></embed>
                        </object>
                        <h3 tw="text-h3 mb-2">{main.title}</h3>
                        <p tw="text-b1">{main.detail}</p>
                      </div>
                      <div>
                        <div tw="flex items-baseline text-blue-black dark:text-white">
                          <p tw="text-h2 font-gilroy mr-4">
                            {main.startFrom ? 'Mulai Rp ' : 'Rp '}
                            {this.formatPrice(main.price)}{' '}
                            <b tw="text-h4">{this.getSufixPrice(main.price)}</b>
                          </p>
                        </div>
                        <div
                          tw="absolute w-full px-6"
                          css={css`
                            left: 0;
                            bottom: -0.2rem;
                          `}
                        >
                          {main.link[0] === '/' ? (
                            <BtnLinkPrimary
                              className="gtm-view-service"
                              to={main.link}
                              tw="w-full text-center dark:border-black-300 px-1 flex items-center justify-center"
                              css={css`
                                border: 4px solid white;
                                padding-top: 10px;
                                padding-bottom: 10px;
                              `}
                            >
                              Cari tahu selengkapnya
                            </BtnLinkPrimary>
                          ) : (
                            <BtnPrimary
                              className="gtm-view-service"
                              href={main.link}
                              tw="w-full text-center dark:border-black-300 px-1 flex items-center justify-center"
                              css={css`
                                border: 4px solid white;
                                padding-top: 10px;
                                padding-bottom: 10px;
                              `}
                            >
                              Cari tahu selengkapnya
                            </BtnPrimary>
                          )}
                        </div>
                      </div>
                    </ProductBox>
                  );
                })}
              </ProductContainer>
              {/* <p tw="text-b1 text-white mb-4">Pilihan paket lainnya:</p> */}
              <ProductContainer>
                {pageData?.otherService?.map((main, i) => {
                  return (
                    <ProductBox key={i + 'productmain'} tw="relative mb-6 lg:mb-2">
                      <div tw="text-black-100 dark:text-white">
                        <object>
                          <embed height="120px" src={this.getIconUrl(main.icon)}></embed>
                        </object>
                        <h3 tw="text-h3 mb-2">{main.title}</h3>
                        <p tw="text-b1">{main.detail}</p>
                      </div>
                      <div>
                        <div tw="flex items-baseline text-blue-black dark:text-white">
                          <p tw="text-h2 font-gilroy mr-4">
                            {main.startFrom ? 'Mulai Rp ' : 'Rp '}
                            {this.formatPrice(main.price)}{' '}
                            <b tw="text-h4">{this.getSufixPrice(main.price)}</b>
                          </p>
                        </div>
                        <div
                          tw="absolute w-full px-6"
                          css={css`
                            left: 0;
                            bottom: -0.2rem;
                          `}
                        >
                          {main.link[0] === '/' ? (
                            <BtnLinkPrimary
                              className="gtm-view-service"
                              to={main.link}
                              tw="w-full text-center dark:border-black-300 px-1 flex items-center justify-center"
                              css={css`
                                border: 4px solid white;
                                padding-top: 10px;
                                padding-bottom: 10px;
                              `}
                            >
                              Cari tahu selengkapnya
                            </BtnLinkPrimary>
                          ) : (
                            <BtnPrimary
                              className="gtm-view-service"
                              href={main.link}
                              tw="w-full text-center dark:border-black-300 px-1 flex items-center justify-center"
                              css={css`
                                border: 4px solid white;
                                padding-top: 10px;
                                padding-bottom: 10px;
                              `}
                            >
                              Cari tahu selengkapnya
                            </BtnPrimary>
                          )}
                        </div>
                      </div>
                    </ProductBox>
                  );
                })}
              </ProductContainer>
              <div tw="text-center">
                <p tw="text-b1 text-white mb-4">Tidak menemukan layanan yang Anda cari?</p>
                <BtnPrimary
                  className="gtm-call-button"
                  href="/hubungi-kami-via-whatsapp"
                  target="__blank"
                  tw="text-center"
                >
                  Hubungi Kami Di Sini
                </BtnPrimary>
              </div>
            </div>
          </div>
        </section>
        <Testimonials></Testimonials>
      </BaseLayout>
    );
  }
}

export default Home;

export const query = graphql`
  query getHome($uid: String!) {
    allContentfulHome(filter: { uid: { eq: $uid } }) {
      nodes {
        seo {
          title
          description {
            description
          }
          keyword
          language
          robots
          author
          publisher
          updatedAt
          oglocale
          ogtype
        }
        title
        jumbotron {
          file {
            url
            fileName
          }
          fluid(maxHeight: 1000) {
            ...ContentfulImageFluidFields
          }
        }
        jumbotronButtonText
        promoBanners {
          description
          title
          fluid(maxHeight: 1000) {
            srcSet
            ...ContentfulImageFluidFields
          }
        }
        bannersDetail {
          title
          description
          hash
          active
          buttonText
          buttonHref
        }
        achievement {
          title
          detail
        }
        partner {
          description
          title
          fluid(maxHeight: 486) {
            srcSet
            ...ContentfulImageFluidFields
          }
        }
        subtitle {
          raw
        }
        mainService {
          title
          detail
          startFrom
          price
          icon
          link
        }
        otherService {
          title
          detail
          startFrom
          price
          icon
          link
        }
      }
    }
    icons: allContentfulAsset(filter: { title: { regex: "/icon-/" } }) {
      nodes {
        title
        file {
          url
          fileName
        }
      }
    }
    jumbotron: file(relativePath: { eq: "images/jumbotron/home.jpg" }) {
      childrenImageSharp {
        fluid(maxWidth: 1440) {
          ...FileImageSharpFluid
        }
      }
    }
    logo: file(relativePath: { eq: "images/logo.png" }) {
      childrenImageSharp {
        fluid(maxWidth: 130) {
          ...FileImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        ...SiteMetadataFields
      }
    }
  }
`;
